@charset "UTF-8";
/* Base Styles */
#cssmenu > ul,
#cssmenu > ul li,
#cssmenu > ul ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
}
#lftSide > ul,
#lftSide > ul li,
#lftSide > ul ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
}
#cssmenu > ul {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  padding: 0 5%;
  position: relative;
  z-index: 597;
}
#lftSide > ul {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  padding: 0 5%;
  position: relative;
  z-index: 597;
}
#cssmenu > ul li {
  min-height: 1px;
  line-height: 1.3em;
  vertical-align: middle;
}
#lftSide > ul li {
  min-height: 1px;
  line-height: 1.3em;
  vertical-align: middle;
}
#cssmenu > ul li.hover,
#cssmenu > ul li:hover {
  position: relative;
  z-index: 599;
  cursor: default;
}
#lftSide > ul li.hover,
#lftSide > ul li:hover {
  position: relative;
  z-index: 599;
  cursor: default;
}
#cssmenu > ul ul {
  visibility: hidden;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 598;
  width: 100%;
}
#cssmenu > ul ul li {
  float: none;
}
#cssmenu > ul ul ul {
  top: 1px;
  left: 99%;
}
#cssmenu > ul li:hover > ul {
  visibility: visible;
}
/* Align last drop down RTL */
#cssmenu > ul > li.last ul ul {
  left: auto !important;
  right: 99%;
}
#cssmenu > ul > li.last ul {
  left: auto;
  right: 0;
}
#cssmenu > ul > li.last {
  text-align: right;
}
/* Theme Styles */

#cssmenu > ul:before {
  content: '';
  display: block;
}
#cssmenu > ul:after {
  content: '';
  display: table;
  clear: both;
}
#lftSide > ul:before {
  content: '';
  display: block;
}
#lftSide > ul:after {
  content: '';
  display: table;
  clear: both;
}
#cssmenu > ul li span {
  display: block;
  padding: 8px 20px;
  color: rgb(255, 255, 255);
  cursor:pointer;
}
#lftSide > ul li span {
  display: block;
  padding: 3px 10px;
  color: rgb(255, 255, 255);
  background: rgba(20, 50, 255);
  cursor:pointer;
}
#cssmenu > ul > li.active,
#cssmenu > ul > li.active:hover {
  background-color: #018bb8;
}
#lftSide > ul > li.active,
#lftSide > ul > li.active:hover {
  background-color: #018bb8;
}
#cssmenu > ul > li > a:link,
#cssmenu > ul > li > a:active,
#cssmenu > ul > li > a:visited {
  color: #ffffff;
}

#cssmenu > ul ul ul {
  top: 0;
}
#cssmenu > ul li li {
  background-color: #076aa3;
  border-left: 2px solid #018bb8;
  border-bottom: 1px solid #ebebeb;
  font-size: 12px;
}
#cssmenu > ul li li > span:hover{
  color:#238;
}
#lftSide > ul li li {
  background-color: #076aa3;
  border-left: 2px solid #018bb8;
  border-bottom: 1px solid #ebebeb;
  font-size: 12px;
}
#lftSide > ul li li > span:hover{
  color:#238;
}

#cssmenu > ul > li.hover,
#cssmenu > ul > li:hover {
  background-color: #eeda10;
  cursor: pointer;
}
#lftSide > ul > li.hover,
#lftSide > ul > li:hover {
  background-color: #eeda10;
  cursor: pointer;
}

#cssmenu > ul > li.hover,
#cssmenu > ul > li:hover {
  background-color: #fff;
  -webkit-box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

#cssmenu > ul > li:hover li > span {
  color: #fff
}

#cssmenu > ul > li:hover span {
  color: #238;
}

#cssmenu > ul > li:hover li:hover {
  background-color: #eeda10;
}
#lftSide > ul > li:hover li:hover {
  background-color: #eeda10;
}

#cssmenu > ul > li:hover li:hover span{
  color: #238;
}

#cssmenu > ul a:link,
#cssmenu > ul a:visited {
  color: #238;
  text-decoration: none;
}
#cssmenu > ul a:hover {
  color: #238;
}
#cssmenu > ul a:active {
  color: #9a9a9a;
}
#cssmenu > ul ul {
  border: 1px solid #CCC \9;
  -webkit-box-shadow: 0 0px 2px 1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0px 2px 1px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0px 2px 1px rgba(0, 0, 0, 0.15);
  width: 150px;
}
#cssmenu .hamburger {
  display: none;
}

@media only screen and (max-width: 920px) {
	#cssmenu > ul {
		display: block;
	}

	#cssmenu >ul > li {
		display: none;
	}

	#cssmenu .hamburger {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    outline: none;
	}

	#cssmenu.active > ul > li {
    display: block;
  }

  #cssmenu.active > ul > li:hover {
    background-color: initial;
  }
  
  #cssmenu.active > ul > li:hover span {
    color: #fff;
  }
  #cssmenu .hamburger:focus ~ li ul li {
    display: none;
    margin: 0;
    position: static;
    visibility: visible;
  }

  #cssmenu.active .has-sub:active > ul,
  #cssmenu.active .has-sub:focus > ul,
  #cssmenu > ul li:hover > ul {
    visibility: visible; 
    position: static;
    margin: 0;
  }
}